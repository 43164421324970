<template>
  <van-uploader
    class="verified-upload"
    v-model="fileList"
    :deletable="false"
    :max-count="1">
    <div class="upload-box">
      <img src="@/assets/img/kctp.png" alt="">
    </div>
  </van-uploader>
</template>

<script>
export default {
  name: 'img_card',
  props:['val'],
  data () {
    return {
      fileList:[],
    }
  },
  mounted () {
    console.log(this.val,'val======')
    this.fileList=[
      {url:`${process.env.VUE_APP_API_PORTAL_URL}/file/image/${this.val}`,
        isImage: true
      }
    ]
  },
  watch:{
   /*  val(data){
      console.log(data,'data=======')
      if(this.fileList.length===0){
        this.fileList=[
          {url:`${process.env.VUE_APP_API_PORTAL_URL}/file/image/${data}`,
            isImage: true
          }
        ]
      }
      if(!data){
        this.fileList=[]
      }
    } */
  }
}
</script>

<style scoped lang="scss">
.verified-upload{
  &::v-deep{
    .van-uploader__wrapper{
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
    .van-uploader__preview{
      margin: 0;
    }
    .van-uploader__preview-image{
      width: 100%;
      height: 100%;
    }
  }
}

</style>
