<template>
  <div class="my-demand-info">
    <span></span>
    <div>
      <div class="my-demand-info-schedule">
        <div :class="{ active: orderInfo.orderStatus >= '1' }">
          <span>
            <van-icon name="checked" v-if="orderInfo.orderStatus == '1'" />
            <van-icon name="passed" v-else />
          </span>
          <p>需求提交</p>
        </div>
        <div :class="{ active: orderInfo.orderStatus >= '2' }">
          <span>
            <template v-if="orderInfo.orderStatus != '5'">
              <van-icon name="checked" v-if="orderInfo.orderStatus == '2'" />
              <van-icon name="passed" v-else />
            </template>
            <van-icon v-else name="clear" />
          </span>
          <p>客服审核</p>
        </div>
        <div
          :class="{
            active:
              orderInfo.orderStatus >= '3' && orderInfo.orderStatus != '5',
          }"
        >
          <span>
            <van-icon name="checked" v-if="orderInfo.orderStatus == '3'" />
            <van-icon name="passed" v-else />
          </span>
          <p>方案制作</p>
        </div>
        <div
          :class="{
            active:
              orderInfo.orderStatus >= '4' && orderInfo.orderStatus != '5',
          }"
        >
          <span>
            <van-icon name="checked" v-if="orderInfo.orderStatus == '4'" />
            <van-icon name="passed" v-else />
          </span>
          <p>方案完成</p>
        </div>
      </div>
      <div class="my-demand-info-dynamic">
        <p>
          <span>进展动态</span>
          <van-button
            class="btn-red"
            @click="auditReason"
            size="mini"
            style="border-radius: 4px"
            v-if="orderInfo.orderStatus == '5'"
            >查看原因</van-button
          >
          <van-button
            class="btn-red"
            @click="downloadAnnex(orderInfo.finishedAttmIds)"
            size="mini"
            style="border-radius: 4px"
            v-if="orderInfo.orderStatus == '4'"
            >下载迁移方案</van-button
          >
        </p>
        <div v-if="orderInfo.orderStatus == '2'">
          <img src="@/assets/img/5346@2x.png" alt="动态" />
          <span>您的需求已提交，请耐心等待平台审核</span>
        </div>
        <div v-if="orderInfo.orderStatus == '3'">
          <img src="@/assets/img/5347@2x.png" alt="动态" />
          <span>您的需求已通过平台审核</span>
        </div>
        <div v-if="orderInfo.orderStatus == '4'">
          <img src="@/assets/img/5348@2x.png" alt="动态" />
          <span>您提交的需求咨询已完成</span>
        </div>
        <div v-if="orderInfo.orderStatus == '5'">
          <img src="@/assets/img/5349@2x.png" alt="动态" />
          <span>平台拒绝了您的请求，请重新提交</span>
        </div>
      </div>
      <div class="my-demand-info-dev">
        <p><span>需求信息</span></p>
        <div>
          <van-swipe indicator-color="#EA0B06">
            <van-swipe-item
              v-for="el in orderInfo.orderDeviceList"
              :key="el.id"
            >
              <div class="my-demand-info-dev-info">
                <div>
                  <span>迁移适配类型</span>
                  <p>
                    {{ orderInfo.adaptationType == "1" ? "硬件产品" : "模组" }}
                  </p>
                </div>
                <div>
                  <span>设备名称</span>
                  <p>{{ el.deviceName }}</p>
                </div>
                <div>
                  <span>设备型号</span>
                  <p>{{ el.deviceModel }}</p>
                </div>
                <div>
                  <span>主控SOC</span>
                  <p>{{ el.socModel }}</p>
                </div>
                <div>
                  <span>SOC型号</span>
                  <p>
                    <template v-if="el.soc != '8'">{{el.soc | dict("DEVICE_SOC")}}</template>
                    <template>{{el.socOther}}</template>
                  </p>
                </div>
                <div>
                  <span>操作系统</span>
                  <p>
                    <template v-if="el.os != '8'">{{el.os | dict("DEVICE_OS")}}</template>
                    <template>{{el.osOther}}</template>
                  </p>
                </div>
                <div>
                  <span>设备及图片</span>
                  <div v-if="el.deviceAttmIds != ''">
                    <template v-for="e in el.deviceAttmIds">
                      <span :key="e">
<!--                        <v-img
                          :attachment-id="e"
                          :default-img="require('@/assets/img/kctp.png')"
                        />-->
                        <imgCard :val="e"></imgCard>
                      </span>

                    </template>
                  </div>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderInfo } from "@/api/order";
import VImg from "@/components/VImg";
import imgCard from '@/page/myDemand/components/img_card'
export default {
  components: {
    VImg,
    imgCard,
  },
  data() {
    return {
      active: 0,
      orderInfo: "",
    };
  },
  computed: {
    id: function () {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.loadOrderInfo();
  },
  created () {
    document.title = '我的国产替代迁移需求'
  },
  methods: {
    loadOrderInfo() {
      getOrderInfo(this.id).then((res) => {
        this.orderInfo = res;
      });
    },
    auditReason() {
      this.$dialog
        .alert({
          confirmButtonText:'我知道了',
          message: this.orderInfo.previewComment,
        }).then(() => {});
    },
    downloadAnnex (val) {
      for (let index = 0; index < val.length; index++) {
        window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${val[index]}`);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.my-demand-info {
  min-height: calc(100vh - 56px);
  padding-bottom: 16px;
  background: #f6f6f6;
  > span {
    display: block;
    height: 69px;
    background: #ea0b06;
  }
  > div {
    position: relative;
    z-index: 2;
    margin-top: -54px;
    padding: 0 16px;
    .my-demand-info-schedule {
      background: #ffffff;
      border-radius: 4px;
      padding: 24px 20px 16px;
      display: flex;
      justify-content: space-between;
      > div {
        > span {
          width: 17px;
          height: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #bfbfbf;
          margin: 0 auto;
          font-size: 12px;
          position: relative;
          > i {
            font-size: 17px;
          }
          &::after {
            content: "";
            position: absolute;
            width: 56px;
            height: 1px;
            background: #eee;
            top: 8px;
            left: -62px;
          }
        }
        > p {
          font-size: 12px;
          line-height: 17px;
          color: #999999;
          text-align: center;
          margin-top: 16px;
        }
        &:first-child {
          > span {
            &::after {
              display: none;
            }
          }
        }
        &.active {
          > span {
            color: #ea0b06;
            &::after {
              background: #ea0b06;
            }
          }
          > p {
            color: #333;
          }
        }
      }
    }
    .my-demand-info-dynamic {
      background: #ffffff;
      border-radius: 4px;
      margin-top: 12px;
      padding: 0 16px;
      > p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        border-bottom: 1px solid #f2f2f2;
        > span {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
      > div {
        padding: 24px 0;
        > img {
          display: block;
          width: 150px;
          margin: 0 auto 16px;
        }
        > span {
          display: block;
          font-size: 12px;
          line-height: 18px;
          color: #666666;
          text-align: center;
        }
      }
    }
    .my-demand-info-dev {
      background: #ffffff;
      border-radius: 4px;
      margin-top: 12px;
      padding: 0 16px;
      > p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        border-bottom: 1px solid #f2f2f2;
        > span {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
      > div {
        .my-demand-info-dev-info {
          padding: 16px 0;
          > div {
            display: flex;
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
            > span {
              width: 108px;
              font-size: 12px;
              line-height: 18px;
              color: #999999;
            }
            > p {
              font-size: 12px;
              line-height: 18px;
              color: #333333;
              flex: 1;
            }
            > div {
              flex: 1;
              display: flex;
              > span {
                width: 60px;
                height: 60px;
                border-radius: 4px;
                margin-right: 10px;
                overflow: hidden;
                &:last-child {
                  margin-right: 0;
                }
                > img {
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.my-demand-info-dev .van-swipe__indicators {
  bottom: 4px;
}
.my-demand-info-dev .van-swipe__indicators > i {
  width: 16px;
  height: 2px;
  background: #eeeeee;
}
</style>
